import React from "react";
import "./Course.css";
import Reviews from "../../assets/images/reviews-img.svg";
import FolderIcon from "../../assets/images/folder-icon.svg";
import Arrow from "../../assets/images/arrow-icon.svg";
import { useNavigate } from "react-router-dom";

export default function Course(props) {
    const navigate = useNavigate();

    return (
        <>
            <div className="courses-card shadow mb-5">
                <img src={props.image} className="img-fluid card-img" alt="" />
                <div className="d-flex justify-content-between align-items-center px-4 mt-3">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="course-profile">
                            <p className="mb-0">A</p>
                        </div>
                        <div className="course-profile-content">
                            <h4 className="mb-0 ps-3">{props.name}</h4>
                        </div>
                    </div>
                    <div className="d-flex review-text">
                        <img src={Reviews} className="img-fluid" alt="" />
                        <p className="mb-0 ps-2">({props.rating})</p>
                    </div>
                </div>
                <div className="px-4 preview-title">
                    <h2 className="mt-3">{props.title}</h2>
                    {/* <p>
                        {currentprice} <span>{previousprice}</span>
                    </p> */}
                </div>

                <div className="lesson-btns">
                    <div className="d-flex justify-content-center align-items-center">
                        <div
                            className="d-flex py-3 justify-content-center"
                            onClick={() => navigate(`/courses-detail/${props.id}`)}
                        >
                            <h3 className="mb-0">View Course</h3>
                            <img src={Arrow} className="img-fluid ps-2" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
