import React, { useEffect, useState } from "react";
import InnerBanner from "../components/banner/InnerBanner";
import Course from "../components/courses/Course";
import CourseImg from "../assets/images/courses-img.png";
import Pricing from "../components/pricing/Pricing";
import Cta from "../components/cta/Cta";
import { courseListApi } from "../api/coursesApi";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

function Courses() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);

    const courseObj = [
        {
            id: "1",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
        {
            id: "2",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
        {
            id: "3",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
        {
            id: "4",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
        {
            id: "5",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
        {
            id: "6",
            title: "Complete Financial Analyst Advance Course",
            currprice: "$50.00",
            prevprice: "$55.00",
            img: CourseImg,
        },
    ];

    const AllCoursesMutation = useMutation(courseListApi, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                setCourses(data.data.courses);
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    useEffect(() => {
        AllCoursesMutation.mutate();
    }, []);

    return (
        <React.Fragment>
            <InnerBanner
                title="THE MERCHANT"
                coursesNum="09"
                courses="Courses"
                studentNum="12"
                students="Students"
            />

            <section className="courses-section">
                <div className="container">
                    <div className="row">
                        {courses.map(courses => {
                            return (
                                <div key={courses.id} className="col-12 col-md-6 col-lg-4">
                                    <Course
                                        key={courses.id}
                                        id={courses.id}
                                        title={courses.course_title}
                                        name={courses.instructor_name}
                                        rating={courses.average_rating}
                                        image={courses.course_image}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <Pricing />
            <Cta />
        </React.Fragment>
    );
}

export default Courses;
