import React, { useState } from "react";
import InnerBanner from "../components/banner/InnerBanner";
import Cta from "../components/cta/Cta";
import Pricing from "../components/pricing/Pricing";

function RiskCalculator() {
    // Declare state variables for the form inputs
    const [accountSize, setAccountSize] = useState("");
    const [riskPerTrade, setRiskPerTrade] = useState("");
    const [stopLoss, setStopLoss] = useState("");
    const [lotSize, setLotSize] = useState("");
    // Declare a state variable for the calculated position size
    const [positionSize, setPositionSize] = useState("");
    const [units, setUnits] = useState("");

    const calculatePositionSize = () => {
        // Convert the input values to numbers
        const accountSizeNum = Number(accountSize);
        const riskPerTradeNum = Number(riskPerTrade);
        const stopLossNum = Number(stopLoss);
        const lotSizeNum = Number(lotSize);

        // Use the position size formula to calculate the size of the position
        const accountAtRisk = (accountSizeNum * riskPerTradeNum) / 100;
        // Update the position size state variable with the calculated value
        setPositionSize(accountAtRisk);

        const calculateUnits = (accountAtRisk / stopLossNum) * lotSizeNum;

        setUnits(calculateUnits);
    };

    return (
        <React.Fragment>
            <InnerBanner
                title="THE MERCHANT"
                coursesNum="09"
                courses="Courses"
                studentNum="12"
                students="Students"
            />

            <section className="risk-calculator-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 px-5 mt-5">
                            <div className="calculator-heading">
                                <h2>Position Size Calculator</h2>
                            </div>
                            <div className="calculator-div shadow">
                                {/* <div className="mb-3">
                                    <label for="accountCurrency" className="form-label">
                                        Account Currency
                                    </label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                    >
                                        <option selected>Open this select menu</option>
                                        <option value="1">ERU</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div> */}
                                <div className="mb-3">
                                    <label className="form-label">Account Balance</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Account Balance"
                                        value={accountSize}
                                        onChange={e => setAccountSize(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Risk Percentage</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Risk Percentage"
                                        value={riskPerTrade}
                                        onChange={e => setRiskPerTrade(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Stop Loss (pips)</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Stop Loss (pips)"
                                        value={stopLoss}
                                        onChange={e => setStopLoss(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Lot Size</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Lot Size"
                                        value={lotSize}
                                        onChange={e => setLotSize(e.target.value)}
                                    />
                                </div>
                                {/* <div className="mb-3">
                                    <label className="form-label">Currency Pair</label>
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                    >
                                        <option selected>Open this select menu</option>
                                        <option value="1">ERU</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div> */}
                            </div>
                            <button className="calculator-btn mt-4" onClick={calculatePositionSize}>
                                Calculate
                            </button>
                        </div>
                        <div className="col-12 col-lg-6 px-5 mt-5">
                            <div className="calculator-heading">
                                <h2>Results</h2>
                                <div className="calculator-div shadow calculator-result">
                                    <div className="mb-3">
                                        <label className="form-label">Amount at Risk</label>
                                        <p>{positionSize} USD</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Units</label>
                                        <p>{units} USD</p>
                                    </div>
                                    {/* <div className="mb-3">
                                        <label className="form-label">Position Size (units)</label>
                                        <p>122,000</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Standard Lots</label>
                                        <p>1.22</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Mini Lots</label>
                                        <p>12.2</p>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Micro Lots</label>
                                        <p>122</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Pricing />
            <Cta />
        </React.Fragment>
    );
}

export default RiskCalculator;
