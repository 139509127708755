import React from "react";
import InnerBanner from "../components/banner/InnerBanner";
import aboutImg from "../assets/images/about-img.jpg";
import videoUrl from "../assets/video/home-vid.mp4";

function AboutUsPage() {
    return (
        <>
            <InnerBanner title="About Us" />
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12 col-lg-8">
                        <p className="about-para">
                            I’m Hesham Elsaid, I was born in Alexandria, Egypt. In 2011 I was
                            selected by the famous Egyptian foundation to go to Italy to study
                            computer & electronics for 1 year (it was a scholarship), finished my
                            study and then found my own path and career between Italy and Poland.
                            I’ve total 10+ years of experience in sales, marketing and project
                            management by working as Business Management and Development Manager
                            across different industries like semiconductors, financial market and
                            e-commerce covering different regions and working with different
                            customers with different backgrounds.
                        </p>
                        <p className="about-para">
                            In the Financial market which I’m passionate about, I am a self directed
                            forex & crypto trader with a proven record of ~25% profit/growth
                            annually. 
                        </p>
                        <p className="about-para mb-4">
                            I started my trading journey back in 2014 and not to hide the first 5
                            years I was losing only! I have gone through what most traders go
                            through at the beginning of the journey (but not everyone talks about
                            it). I started with the urge to make money as quick as I can and to
                            become a millionaire as this is what I could see on social media (The
                            first lesson which I learned with the time), having this in mind i
                            started to blindly follow people on social media and be part of paid
                            groups which share trades on Telegram, Discord or whatever you name. but
                            still I was losing money, nerves, hope, desire and started to doubt
                            myself that this is not for me. not to make you read a lot, you can
                            watch this video here to know the rest of the story.
                        </p>
                        <h2>What am I trying to do here? </h2>
                        <p className="about-para">
                            <strong>First of all</strong>, I will try to warn you about mistakes
                            and traps which I fall into at the beginning of my journey, so at least
                            you will save this money instead of losing it. 
                        </p>
                        <p className="about-para">
                            <strong>Second,</strong> I will share with you all the knowledge and
                            experience which i gained in this market, also you will get access to
                            30+ videos and course lessons which will teach you everything you need
                            like: what is forex, market basics, brokers, tools like (MT4 & MT5),
                            fundamentals, technical analysis, indicators and how they work, how to
                            find trades and how to execute them, risk management, different trading
                            plans and market psychology.
                        </p>
                        <p className="about-para">
                            <strong>Third,</strong> which is the most important in my opinion is the
                            right mindset and mentality you should have as a trader.
                        </p>
                        <p className="about-para">
                            My goal is to build a community of conscious, humble, disciplined,
                            realistic, mindful and real traders & investors, as well as I try to
                            simplify the forex market so it can be easily understood and accessible
                            to everyone. 
                        </p>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="course-video-section ratio ratio-16x9 mb-5">
                            <video width="100%" key={videoUrl} controls>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                                <track
                                    src="fgsubtitles_en.vtt"
                                    kind="subtitles"
                                    srcLang="en"
                                    label="English"
                                />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutUsPage;
