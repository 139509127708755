import React from "react";
import QRImage from "../assets/images/qr-image.svg";

function TelegramQR() {
    return (
        <section className="telegram-section">
            <div className="container-fluid px-6">
                <div className="row telegram-row bg-white align-items-center">
                    <div className="col-12 col-lg-6">
                        <div className="qr-content">
                            <h1>QR CODE SCAN</h1>
                            <ul>
                                <li>
                                    Quis massa odio habitasse viverra morbi ac. Velit ornare et leo.
                                </li>
                                <li>
                                    Porttitor aliquet varius sit maecenas auctor. Sit enim quis
                                    auctor.
                                </li>
                                <li>
                                    Sed facilisi gravida ut risus amet ornare. Aenean odio diam.
                                </li>
                                <li>
                                    Egestas pellentesque nibh aliquet a lacus eget sodales. Ipsum.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="qr-img">
                            <img src={QRImage} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TelegramQR;
