import React, { useState } from "react";
import "./Footer.css";
import FooterLogo from "../../assets/images/footer-logo.svg";
import Insta from "../../assets/images/insta-icon.svg";
import FbIcon from "../../assets/images/fb-icon.svg";
import Twitter from "../../assets/images/twitter-icon.svg";
import LinkIn from "../../assets/images/linkin-icon.svg";
import Tiktok from "../../assets/images/tiktok-icon.svg";
import Youtube from "../../assets/images/youtube-icon.svg";
import { useNavigate } from "react-router-dom";
import newsletterApi from "../../api/newsletterApi";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import Fetcher from "../../library/Fetcher";

export default function Footer() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [email, setEmail] = useState();

    const navigate = useNavigate();

    const handleChangeInput = event => {
        let name = event.target.name;
        if (name === "email") {
            setEmail(event.target.value);
        }

        setFormData({
            ...formData,
            [name]: event.target.value,
        });
    };

    // const newsletterMutation = useMutation(newsletterApi, {
    //     onSuccess: data => {
    //         console.log(data);
    //         setLoading(false);
    //         if (data?.data) {
    //             // sessionStorage.setItem("email", data?.data?.user_email);
    //             toast.success(data?.data.message, {
    //                 position: "top-right",
    //                 autoClose: 2000,
    //                 hideProgressBar: true,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: false,
    //                 progress: undefined,
    //             });
    //         }

    //         return;
    //     },
    //     onError: error => {
    //         console.log("error", error);
    //         setLoading(false);
    //         if (error?.response?.data?.errors) {
    //             setErrors(error?.response.data.errors);
    //             return;
    //         }
    //     },
    // });

    const newsletterMutation = useMutation(
        uploadedData => Fetcher.post(`/api/newsLetterSubscription`, uploadedData),
        {
            onSuccess: data => {
                console.log(data);
                setLoading(false);
                if (data?.data) {
                    // sessionStorage.setItem("email", data?.data?.user_email);
                    toast.success(data?.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                return;
            },
            onError: error => {
                console.log("error", error);
                setLoading(false);
                if (error?.response?.data?.errors) {
                    setErrors(error?.response.data.errors);
                    return;
                }
            },
        }
    );

    const onSubscribeHandler = e => {
        e.preventDefault();

        setLoading(true);
        newsletterMutation.mutate(formData);
        // console.log(formData);
    };

    return (
        <>
            <section className="footer">
                <div className="container-fluid py-5">
                    <div className="row px-2 px-lg-5">
                        <div className="col-12 col-md-6 col-lg-3 footer-info">
                            <img src={FooterLogo} className="img-fluid footer-logo" alt="" />
                            <p className="pt-4">Revolutionizing The Forex Trading</p>
                            <h6 className="follow-footer mb-0">Follow Us on Soical Media</h6>
                            <div className="d-flex mt-2">
                                <a href="https://www.instagram.com/thisishesham/" target="_blank">
                                    <img src={Insta} className="img-fluid pe-3" alt="" />
                                </a>
                                <a href="https://www.facebook.com/eltaajirtrading" target="_blank">
                                    <img src={FbIcon} className="img-fluid px-3" alt="" />
                                </a>
                                <a href="https://twitter.com/Hesham_Elsaid_" target="_blank">
                                    <img src={Twitter} className="img-fluid px-3" alt="" />
                                </a>
                                <a
                                    href="https://www.linkedin.com/in/hesham-elsaid/"
                                    target="_blank"
                                >
                                    <img src={LinkIn} className="img-fluid px-3" alt="" />
                                </a>
                                <a href="https://www.tiktok.com/@eltaajir?lang=en" target="_blank">
                                    <img src={Tiktok} className="img-fluid px-3" alt="" />
                                </a>
                                <a
                                    href="https://www.youtube.com/channel/UCX64WdWCUK9aesPlNjPLJbQ"
                                    target="_blank"
                                >
                                    <img src={Youtube} className="img-fluid px-3" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 footer-links">
                            <h2>COMPANY</h2>
                            <p style={{ cursor: "pointer" }} onClick={() => navigate("/about-us")}>
                                About Us
                            </p>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/resource-center")}
                            >
                                Resource Center
                            </p>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/documentation")}
                            >
                                Documentation
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 footer-links">
                            <h2>RESOURCES</h2>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/terms-and-conditions")}
                            >
                                Terms & Conditions
                            </p>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/privacy-policy")}
                            >
                                Privacy Policy
                            </p>
                            <p
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/video-guide")}
                            >
                                Video Guides
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 footer-links">
                            <h2>RESOURCES</h2>
                            <p style={{ cursor: "pointer" }} onClick={() => navigate("/community")}>
                                Community
                            </p>
                            <p style={{ cursor: "pointer" }} onClick={() => navigate("/support")}>
                                Support
                            </p>

                            <p style={{ cursor: "pointer" }} onClick={() => navigate("/security")}>
                                Security
                            </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-2 footer-form">
                            <h2>Subscribe to Our Offers & News</h2>
                            <div className="mb-2">
                                <input
                                    type="text"
                                    className="form-control mt-4"
                                    placeholder="Your Email*"
                                    onChange={e => handleChangeInput(e)}
                                    name="email"
                                />
                            </div>
                            <button onClick={e => onSubscribeHandler(e)}>SUBSCRIBE</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
