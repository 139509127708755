import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { courseDetailApi } from "../api/coursesApi";
import InnerBanner from "../components/banner/InnerBanner";
import CourseInfo from "../components/course-info/CourseInfo";
import CourseVideo from "../components/course-video/CourseVideo";
import Cta from "../components/cta/Cta";
import Pricing from "../components/pricing/Pricing";

function CourseDetail() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [courseData, setCourseData] = useState([]);

    let { id } = useParams();
    const courseDeatailMutate = useMutation(courseDetailApi, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                setCourseData(data?.data?.data);
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    useEffect(() => {
        courseDeatailMutate.mutate(id);
    }, []);

    return (
        <React.Fragment>
            <InnerBanner
                title="THE MERCHANT"
                coursesNum="09"
                courses="Courses"
                studentNum="12"
                students="Students"
            />
            {/* {courseData && courseData.length > 0 && console.log("after", courseData)} */}
            <CourseVideo data={courseData} />
            <CourseInfo />
            <Pricing />
            <Cta />
        </React.Fragment>
    );
}

export default CourseDetail;
