import React, { useEffect, useState } from "react";
import "./Login.css";
import LoginImg from "../../assets/images/login-img.svg";
import Logo from "../../assets/images/logo.svg";
import MesgIcon from "../../assets/images/mesg-icon.svg";
import LockIcon from "../../assets/images/lock-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { forgotPasswordApi, login } from "../../api/authApi";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Fetcher from "../../library/Fetcher";
import Spinner from "../../custom-components/Spinner";

export default function ForgotPassword() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const handleChangeInput = event => {
        let name = event.target.name;
        if (name === "email") {
            setEmail(event.target.value);
        }

        setFormData({
            ...formData,
            [name]: event.target.value,
        });
    };

    const forgotPasswordMutation = useMutation(forgotPasswordApi, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                localStorage.setItem("email", email);
                toast.success(data?.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    const onEmailSubmitHandler = () => {
        setLoading(true);
        forgotPasswordMutation.mutate(formData);
    };

    return (
        <>
            {loading && <Spinner />}
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12 col-md-6 col-lg-6 login-img d-flex justify-content-center align-items-center">
                        <img src={LoginImg} className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 login-form py-4 d-flex flex-column justify-content-center align-items-center ">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img
                                    src={Logo}
                                    className="img-fluid"
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate("/")}
                                />
                                <h2 className="mt-2">Login into your account</h2>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 mb-3 mt-5 position-relative">
                            <label className="form-label">Email Id:</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter your Email"
                                name="email"
                                // value={email}
                                onChange={e => handleChangeInput(e)}
                            />
                            <div className="input-icon">
                                <img src={MesgIcon} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 form-login-btn mt-4">
                            <button onClick={() => onEmailSubmitHandler()}>Submit</button>
                        </div>
                        <div className="separator"></div>
                        <div className="col-12 col-lg-6 form-signup-btn">
                            <button onClick={() => navigate("/signup")}>Signup now</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
