import React from "react";

function ProfilePage() {
    return (
        <section className="white-section py-5">
            <div className="container shadow py-5 px-6">
                <div className="row mb-4">
                    <div className="col-12 col-lg-12">
                        <h2>Your Profile</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-4 mb-5">
                        <div className="profile-form-group">
                            <label htmlFor="username">Username :</label>
                            <input type="text" placeholder="Enter your username..." />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-5">
                        <div className="profile-form-group">
                            <label htmlFor="fname">First Name :</label>
                            <input type="text" placeholder="Enter your first name..." />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-5">
                        <div className="profile-form-group">
                            <label htmlFor="lname">Last Name :</label>
                            <input type="text" placeholder="Enter your last name..." />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                        <div className="profile-form-group">
                            <label htmlFor="mobile">Mobile No :</label>
                            <input type="number" placeholder="+92 - 1234 56789" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                        <div className="profile-form-group">
                            <label htmlFor="lname">D.O.B :</label>
                            <input type="date" placeholder="01/12/2022" />
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12 text-center">
                        <button className="lms-btn">Save</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProfilePage;
