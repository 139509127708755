import HomePage from "./pages/HomePage";
import Courses from "./pages/Courses";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import CourseDetail from "./pages/CourseDetail";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import TelegramQR from "./pages/TelegramQR";
import TradeView from "./pages/TradeView";
import Login from "./components/login/Login";
import SignUp from "./components/singup/SignUp";
import { useEffect, useState } from "react";
import RiskCalculator from "./pages/RiskCalculator";
import ProfilePage from "./pages/ProfilePage";
import PaymentPage from "./pages/PaymentPage";
import MemberPage from "./pages/MemberPage";
import News from "./pages/News";
import AboutUsPage from "./pages/AboutUsPage";
import { ToastContainer, toast } from "react-toastify";
import OtpPage from "./pages/OtpPage";
import ForgotPassword from "./components/login/ForgotPassword";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import DocumentationPage from "./pages/DocumentationPage";
import CommunityPage from "./pages/CommunityPage";
import VideoGuide from "./pages/VideoGuide";
import SupportPage from "./pages/SupportPage";
import SecurityPage from "./pages/SecurityPage";
import ResourcePage from "./pages/ResourcePage";
import InnerBlogPage from "./pages/InnerBlogPage";
import ConfirmPassword from "./components/login/ConfirmPassword";
// import 'react-toastify/dist/ReactToastify.css';

function App() {
    const location = useLocation();

    return (
        <>
            <ToastContainer />
            {location.pathname !== "/signup" &&
                location.pathname !== "/login" &&
                location.pathname !== "/otp" &&
                location.pathname !== "/forgot-password" &&
                location.pathname !== "/confirm-password/:id" && <Navbar />}
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/otp" element={<OtpPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses-detail/:id" element={<CourseDetail />} />
                <Route path="/telegram-qr" element={<TelegramQR />} />
                <Route path="/trading-view" element={<TradeView />} />
                <Route path="/risk-calculator" element={<RiskCalculator />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/membership" element={<MemberPage />} />
                <Route path="/news" element={<News />} />
                <Route path="/news-detail/:id" element={<InnerBlogPage />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditionPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/documentation" element={<DocumentationPage />} />
                <Route path="/community" element={<CommunityPage />} />
                <Route path="/video-guide" element={<VideoGuide />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/security" element={<SecurityPage />} />
                <Route path="/resource-center" element={<ResourcePage />} />
                <Route path="/confirm-password/:id" element={<ConfirmPassword />} />
            </Routes>

            {location.pathname !== "/signup" &&
                location.pathname !== "/login" &&
                location.pathname !== "/otp" &&
                location.pathname !== "/forgot-password" &&
                location.pathname !== "/confirm-password/:id" && <Footer />}
        </>
    );
}

export default App;
