import React from "react";
import InnerBanner from "../components/banner/InnerBanner";
import Pricing from "../components/pricing/Pricing";

function MemberPage() {
    return (
        <>
            <InnerBanner title="MEMBERSHIP" />
            <div className="membershipPrice">
                <Pricing />
            </div>
        </>
    );
}

export default MemberPage;
