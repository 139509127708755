import React, { useEffect, useState } from "react";
import { Chart } from "../components/chart/Chart";
import Cta from "../components/cta/Cta";
import Pricing from "../components/pricing/Pricing";

export const useScript = (url, name) => {
    const [lib, setLib] = useState({});

    useEffect(() => {
        const script = document.createElement("script");

        script.src = url;
        script.async = true;
        script.onload = () => setLib({ [name]: window[name] });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);

    return lib;
};

function TradeView(props) {
    const { TradingView } = useScript("https://s3.tradingview.com/tv.js", "TradingView");

    const tradeFunc = () => {
        new TradingView.widget({
            width: "100%",
            height: 850,
            symbol: "CAPITALCOM:DXY",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: true,
            withdateranges: true,
            range: "YTD",
            hide_side_toolbar: false,
            allow_symbol_change: true,
            watchlist: [
                "TVC:USOIL",
                "OANDA:XAUUSD",
                "FX:EURUSD",
                "FX:EURGBP",
                "FX:GBPUSD",
                "FX:GBPJPY",
                "FX:EURAUD",
                "FX:EURNZD",
                "FX:USDJPY",
                "FX:AUDUSD",
                "FX:NZDUSD",
                "FX:USDCAD",
                "FX:EURJPY",
                "FX:USDCHF",
                "FX:GBPAUD",
                "FX:AUDCAD",
                "FX:AUDJPY",
                "FX:NZDJPY",
                "FX:NZDCAD",
                "FX:EURCAD",
                "FX:AUDNZD",
                "FX:USDNOK",
                "FX:GBPNZD",
                "FX:EURNOK",
                "FX:CHFJPY",
                "FX:CADJPY",
                "CURRENCYCOM:US30",
            ],
            details: true,
            hotlist: true,
            calendar: true,
            container_id: "tradingview_62f79",
        });
    };

    setTimeout(() => {
        tradeFunc();
    }, 100);

    return (
        <React.Fragment>
            <div className="trade-banner mt-5 mb-5">
                <div className="container-fluid px-6">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>TradingView</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* <TradeSummary /> */}

            <div className="container-fluid px-6 pb-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tradingview-widget-container">
                            <div id="tradingview_62f79"></div>
                            {/* <div className="tradingview-widget-copyright">
                                <a
                                    href="https://www.tradingview.com/symbols/CAPITALCOM-DXY/"
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <span className="blue-text">DXY chart</span>
                                </a>{" "}
                                by TradingView
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className="chart-section">
                <div className="container-fluid px-6">
                    <div className="row chart-row">
                        <div className="col-lg-12">
                            <Chart colors={colors} data={initialData}></Chart>
                        </div>
                    </div>
                </div>
            </section> */}
            <Pricing />
            <Cta />
        </React.Fragment>
    );
}

export default TradeView;
