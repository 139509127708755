import React from "react";
import StarRating from "../../assets/images/blue-stars.png";

function InnerBanner(props) {
    return (
        <section className="inner-banner">
            <div className="container-fluid px-6">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-banner-text">
                            <h1>{props.title}</h1>
                            <p>
                                <span>
                                    {props.coursesNum}
                                    {props.courses} {props.studentNum}
                                    {props.students}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InnerBanner;
