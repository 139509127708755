import React, { useEffect, useState } from "react";
import InnerBanner from "../components/banner/InnerBanner";
import forexImg from "../assets/images/forex-img.jpeg";
import { blogList } from "../api/blogApi";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../custom-components/Spinner";

function News() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);

    const navigate = useNavigate();

    const blogListMutation = useMutation(blogList, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                setBlogs(data?.data?.data);
                toast.success(data?.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    useEffect(() => {
        setLoading(true);
        blogListMutation.mutate();
    }, []);

    return (
        <>
            {loading && <Spinner />}

            <InnerBanner title="News" />

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-8">
                        {blogs &&
                            blogs.length > 0 &&
                            blogs.map(item => {
                                return (
                                    <div
                                        className="news-wrap d-flex align-items-center"
                                        key={item.id}
                                        onClick={() => navigate(`/news-detail/${item.id}`)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <div className="news-img me-4">
                                            <img src={item.blog_image} alt="" />
                                        </div>
                                        <div className="news-content">
                                            <p className="news-tag mb-0">NEWS</p>
                                            <h5 className="news-title mt-2">{item.blog_title}</h5>
                                            <p className="news-hour mb-2">{item.hours} hours ago</p>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item.description,
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="col-lg-4">
                        {/* <img src={forexImg} alt="" className="img-fluid ms-2" /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default News;
