import React from "react";
import { useNavigate } from "react-router-dom";
import "./Pricing.css";
import { useTranslation } from "react-i18next";

export default function Pricing() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    let haveToken = sessionStorage.getItem("token");

    return (
        <>
            <section className="pricing-bg">
                <div className="container-fluid py-5 px-6">
                    <div className="row justify-content-center mx-2">
                        <div className="col-12 text-center py-3 pricing-card-heading">
                            <h2>{t("plan_and_price")}</h2>
                        </div>
                        <div className="text-center">
                            <div
                                className="nav nav-pills me-3 justify-content-center"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <button
                                    className="nav-link active"
                                    id="v-pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    {t("package_1")}
                                </button>
                                <button
                                    className="nav-link"
                                    id="v-pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    {t("package_2")}
                                </button>
                            </div>
                            <div className="tab-content pb-4" id="v-pills-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="v-pills-home"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-home-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6 col-lg-3 pricing-card mx-0 mx-lg-3 mt-3">
                                            <div className="pricing-card-content">
                                                <span>{t("one_time_payment")}</span>
                                                <br />
                                                {/* <span>(lifetime access to the material)</span> */}
                                                <h1>
                                                    <span className="me-2 del-price">
                                                        <del>$130</del>
                                                    </span>
                                                    $100
                                                </h1>
                                                {/* <p>per user, per month</p> */}
                                                <hr></hr>
                                                <ul className="price-height">
                                                    <li className="mb-2">
                                                        {t("what_will_you_get")}
                                                    </li>
                                                    <li className="mb-2">
                                                        {t("you_wil_get_access_to_30")}
                                                        <ul className="inner-ul">
                                                            <li>{t("what_is_forex")}</li>
                                                            <li>{t("market_basics")}</li>
                                                            <li>{t("broker")}</li>
                                                            <li>{t("tools_like_mt4")}</li>
                                                            <li>{t("fundamentals")}</li>
                                                            <li>{t("technical_analysis")}</li>
                                                            <li>
                                                                {t("indicators_and_how_they_work")}
                                                            </li>
                                                            <li>{t("how_to_find_trades")}</li>
                                                            <li>{t("risk_management")}</li>
                                                            <li>{t("different_trading_plans")}</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        haveToken
                                                            ? navigate("/payment", {
                                                                  state: {
                                                                      title: "One Time Payment",
                                                                      price: "100",
                                                                  },
                                                              })
                                                            : navigate("/login")
                                                    }
                                                >
                                                    {t("purchase")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="v-pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="v-pills-profile-tab"
                                >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4 className="mt-4 mb-0 text-white">
                                                {t("you_can_cancel_anytime")}
                                                <br />
                                                {t("these_prices_are_only_for")}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        <div className="col-12 col-md-6 col-lg-3 text-center mx-0 mt-3">
                                            <div className="pricing-card-content pricing-card">
                                                <span>{t("monthly")}</span>
                                                <h1>
                                                    <span className="me-2 del-price">
                                                        <del>$60</del>
                                                    </span>
                                                    $45
                                                </h1>
                                                <hr></hr>
                                                <ul className="price-height">
                                                    <li className="mb-2">
                                                        {t(
                                                            "renews_every_month_after_frist_3_months"
                                                        )}
                                                    </li>
                                                    <li className="mb-2">
                                                        {t("what_will_you_get")}
                                                    </li>
                                                    <li className="mb-2">
                                                        <ul>
                                                            <li className="mb-2">
                                                                {t("you_wil_get_access_to_30")}
                                                                <ul className="inner-ul">
                                                                    <li>{t("what_is_forex")}</li>
                                                                    <li>{t("market_basics")}</li>
                                                                    <li>{t("broker")}</li>
                                                                    <li>{t("tools_like_mt4")}</li>
                                                                    <li>{t("fundamentals")}</li>
                                                                    <li>
                                                                        {t("technical_analysis")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "indicators_and_how_they_work"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("how_to_find_trades")}
                                                                    </li>
                                                                    <li>{t("risk_management")}</li>
                                                                    <li>
                                                                        {t(
                                                                            "different_trading_plans"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("weekly_market_review")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "weekly_call_for_the_community"
                                                                        )}
                                                                    </li>
                                                                    <li>{t("signals_group")}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        haveToken
                                                            ? navigate("/payment", {
                                                                  state: {
                                                                      title: "Monthly",
                                                                      price: "45",
                                                                  },
                                                              })
                                                            : navigate("/login")
                                                    }
                                                >
                                                    {t("purchase")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mx-0 mt-3">
                                            <div className="pricing-card-content pricing-card">
                                                <span>{t("months_3")}</span>
                                                <h1>
                                                    <span className="me-2 del-price">
                                                        <del>$60</del>
                                                    </span>
                                                    $40
                                                </h1>
                                                <span>{t("total_price_$120_for_3_months")}</span>
                                                <hr></hr>
                                                <ul className="price-height">
                                                    <li className="mb-2">
                                                        {t(
                                                            "renews_every_month_after_frist_3_months"
                                                        )}
                                                    </li>
                                                    <li className="mb-2">
                                                        {t("what_will_you_get")}
                                                    </li>
                                                    <li className="mb-2">
                                                        <ul>
                                                            <li className="mb-2">
                                                                {t("you_wil_get_access_to_30")}
                                                                <ul className="inner-ul">
                                                                    <li>{t("what_is_forex")}</li>
                                                                    <li>{t("market_basics")}</li>
                                                                    <li>{t("broker")}</li>
                                                                    <li>{t("tools_like_mt4")}</li>
                                                                    <li>{t("fundamentals")}</li>
                                                                    <li>
                                                                        {t("technical_analysis")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "indicators_and_how_they_work"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("how_to_find_trades")}
                                                                    </li>
                                                                    <li>{t("risk_management")}</li>
                                                                    <li>
                                                                        {t(
                                                                            "different_trading_plans"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("weekly_market_review")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "weekly_call_for_the_community"
                                                                        )}
                                                                    </li>
                                                                    <li>{t("signals_group")}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        haveToken
                                                            ? navigate("/payment", {
                                                                  state: {
                                                                      title: "3 Months",
                                                                      price: "120",
                                                                  },
                                                              })
                                                            : navigate("/login")
                                                    }
                                                >
                                                    {t("purchase")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mx-0 mt-3">
                                            <div className="pricing-card-content pricing-card">
                                                <span>{t("months_6")}</span>
                                                <h1>
                                                    <span className="me-2 del-price">
                                                        <del>$60</del>
                                                    </span>
                                                    $35
                                                </h1>
                                                <span>{t("total_price_$210_for_6_months")}</span>
                                                <hr></hr>
                                                <ul className="price-height">
                                                    <li className="mb-2">
                                                        {t(
                                                            "renews_every_month_after_frist_3_months"
                                                        )}
                                                    </li>
                                                    <li className="mb-2">
                                                        {t("what_will_you_get")}
                                                    </li>
                                                    <li className="mb-2">
                                                        <ul>
                                                            <li className="mb-2">
                                                                {t("you_wil_get_access_to_30")}
                                                                <ul className="inner-ul">
                                                                    <li>{t("what_is_forex")}</li>
                                                                    <li>{t("market_basics")}</li>
                                                                    <li>{t("broker")}</li>
                                                                    <li>{t("tools_like_mt4")}</li>
                                                                    <li>{t("fundamentals")}</li>
                                                                    <li>
                                                                        {t("technical_analysis")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "indicators_and_how_they_work"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("how_to_find_trades")}
                                                                    </li>
                                                                    <li>{t("risk_management")}</li>
                                                                    <li>
                                                                        {t(
                                                                            "different_trading_plans"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("weekly_market_review")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "weekly_call_for_the_community"
                                                                        )}
                                                                    </li>
                                                                    <li>{t("signals_group")}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        haveToken
                                                            ? navigate("/payment", {
                                                                  state: {
                                                                      title: "6 Months",
                                                                      price: "210",
                                                                  },
                                                              })
                                                            : navigate("/login")
                                                    }
                                                >
                                                    {t("purchase")}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-3 mx-0 mt-3">
                                            <div className="pricing-card-content pricing-card">
                                                <span>{t("yearly")}</span>
                                                <h1>
                                                    <span className="me-2 del-price">
                                                        <del>$60</del>
                                                    </span>
                                                    $30
                                                </h1>
                                                <span>{t("total_price_$360_per_year")}</span>
                                                <hr></hr>
                                                <ul className="price-height">
                                                    <li className="mb-2">
                                                        {t(
                                                            "renews_every_month_after_frist_3_months"
                                                        )}
                                                    </li>
                                                    <li className="mb-2">
                                                        {t("what_will_you_get")}
                                                    </li>
                                                    <li className="mb-2">
                                                        <ul>
                                                            <li className="mb-2">
                                                                {t("you_wil_get_access_to_30")}
                                                                <ul className="inner-ul">
                                                                    <li>{t("what_is_forex")}</li>
                                                                    <li>{t("market_basics")}</li>
                                                                    <li>{t("broker")}</li>
                                                                    <li>{t("tools_like_mt4")}</li>
                                                                    <li>{t("fundamentals")}</li>
                                                                    <li>
                                                                        {t("technical_analysis")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "indicators_and_how_they_work"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("how_to_find_trades")}
                                                                    </li>
                                                                    <li>{t("risk_management")}</li>
                                                                    <li>
                                                                        {t(
                                                                            "different_trading_plans"
                                                                        )}
                                                                    </li>
                                                                    <li>
                                                                        {t("weekly_market_review")}
                                                                    </li>
                                                                    <li>
                                                                        {t(
                                                                            "weekly_call_for_the_community"
                                                                        )}
                                                                    </li>
                                                                    <li>{t("signals_group")}</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                <button
                                                    onClick={() =>
                                                        haveToken
                                                            ? navigate("/payment", {
                                                                  state: {
                                                                      title: "Yearly",
                                                                      price: "360",
                                                                  },
                                                              })
                                                            : navigate("/login")
                                                    }
                                                >
                                                    {t("purchase")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
