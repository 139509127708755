import React, { useState } from "react";
import "./SignUp.css";
import SignImg from "../../assets/images/Signup-img.svg";
import Logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import { login, register } from "../../api/authApi";
import { useMutation } from "@tanstack/react-query";
import app_url from "../../config/constants";
import Fetcher from "../../library/Fetcher";
import { toast } from "react-toastify";
import axios from "axios";
import Spinner from "../../custom-components/Spinner";
// import { toast } from "react-toastify";

export default function SignUp() {
    const navigate = useNavigate();
    // states
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleChangeInput = event => {
        let name = event.target.name;
        if (name === "first_name") {
            setFname(event.target.value);
        } else if (name === "last_name") {
            setLname(event.target.value);
        } else if (name === "email") {
            setEmail(event.target.value);
        } else if (name === "contact") {
            setMobileNumber(event.target.value);
        } else if (name === "password") {
            setPassword(event.target.value);
        } else if (name === "confirm_password") {
            setConfirmPassword(event.target.value);
        }

        setFormData({
            ...formData,
            [name]: event.target.value,
        });
    };

    // const updateCaseMutation = useMutation(
    //     uploadedData => Fetcher.post(`/register`, uploadedData),
    //     {
    //         onSuccess: data => {
    //             console.log(data);
    //             setLoading(false);
    //             if (data?.data?.status) {
    //                 toast.success(data.data.success, {
    //                     position: "top-right",
    //                     autoClose: 2000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: false,
    //                     progress: undefined,
    //                 });
    //             }
    //             return;
    //         },
    //         onError: error => {
    //             console.log("error", error);
    //             setLoading(false);
    //         },
    //     }
    // );

    const registerUserMutation = useMutation(register, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                toast.success(data.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            navigate("/login");

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    const onSignup = async () => {
        setLoading(true);
        registerUserMutation.mutate(formData);
    };

    return (
        <>
            {loading && <Spinner />}
            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col-12 col-lg-6 login-form py-4 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="text-center">
                                <img src={Logo} className="img-fluid" alt="" />
                                <h2 className="mt-2">Sign up into your account</h2>
                            </div>
                            <div className="row justify-content-center align-items-start mt-5">
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">First Name :</label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        placeholder="Enter your first name.."
                                        className="form-control"
                                        value={fname}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">Last Name :</label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        className="form-control"
                                        placeholder="Enter your last name.."
                                        value={lname}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">Email Id :</label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="info@xyz.com"
                                        value={email}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">Mobile No. :</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="+92 - 1234 56789"
                                        name="contact"
                                        value={mobileNumber}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">Password :</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="xxxxxxxxxx"
                                        name="password"
                                        value={password}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-4">
                                    <label className="form-label">Confirm Password :</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="xxxxxxxxxx"
                                        name="confirm_password"
                                        value={confirmPassword}
                                        onChange={e => handleChangeInput(e)}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 form-login-btn mt-5">
                                    <button onClick={() => onSignup()}>Sign up</button>
                                </div>
                                <div className="col-12 mt-4 text-center signup-text">
                                    <p>
                                        already have an account?{" "}
                                        <span onClick={() => navigate("/login")}>Login</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 sign-img d-flex justify-content-center align-items-center">
                        <img src={SignImg} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}
