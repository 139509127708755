import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import VideoRating from "../../assets/images/video-rating.png";

function PreviewCourseModal({ data, isOpen, onClose }) {
    const videoRef = useRef();

    const stopPreviewVideo = () => {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
    };

    return (
        <React.Fragment>
            {isOpen && (
                <div className="modal-wrap">
                    <div className="backdrop" onClick={onClose} />
                    <div className="customModal modal-card">
                        <header className="header">
                            <h2>{data.title}</h2>
                        </header>
                        <div className="content">
                            <video
                                width="100%"
                                key={data.id}
                                ref={videoRef}
                                /* height="500px" */ controls
                            >
                                <source src={data.course_video} type="video/mp4" />
                                Your browser does not support the video tag.
                                <track
                                    src="fgsubtitles_en.vtt"
                                    kind="subtitles"
                                    srcLang="en"
                                    label="English"
                                />
                                <track
                                    src="fgsubtitles_no.vtt"
                                    kind="subtitles"
                                    srcLang="no"
                                    label="Norwegian"
                                />
                            </video>
                        </div>
                        <footer className="actions">
                            <button className="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                        </footer>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default PreviewCourseModal;
