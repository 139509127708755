import Fetcher from "../library/Fetcher";

export const register = async data => {
    return Fetcher.post("/register", data);
};

export const login = async data => {
    return Fetcher.post("/login", data);
};

export const logout = async data => {
    return Fetcher.post("/logout", data);
};

export const sendOtp = async data => {
    return Fetcher.post("/verifyOtp", data);
};

export const resendOtpApi = async data => {
    return Fetcher.post("/resendOtp", data);
};

export const forgotPasswordApi = async data => {
    return Fetcher.post("/forgetPassword", data);
};

export const resetPasswordApi = async data => {
    return Fetcher.post("/resetPassword", data);
};

export default {
    login,
    register,
    logout,
    resendOtpApi,
    sendOtp,
    forgotPasswordApi,
    resetPasswordApi,
};
