import React, { useEffect, useState } from "react";
import Banner from "../components/banner/Banner";
import BenefitWorking from "../components/benefit/BenefitWorking";
import Education from "../assets/images/educate.png";
import Transparency from "../assets/images/transparency.png";
import Trades from "../assets/images/trades.png";
import LiveSession from "../assets/images/live-session.png";
import Community from "../assets/images/community.png";
import Purpose from "../assets/images/purpose.png";
import Course from "../components/courses/Course";
import CourseImg from "../assets/images/courses-img.png";
import ManyCourses from "../many-courses/ManyCourses";
import Cta from "../components/cta/Cta";
import Counter from "../components/counter/Counter";
import Pricing from "../components/pricing/Pricing";
import PreviewCourse from "../components/courses/PreviewCourse";
import PreviewCourseModal from "../components/courses/PreviewCourseModal";
import { useTranslation } from "react-i18next";
import { previewCoursesApi } from "../api/coursesApi";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

export default function HomePage() {
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [previewCourses, setPreviewCourses] = useState([]);

    const { t } = useTranslation();

    const benefitObj = [
        {
            id: "1",
            title: t("education"),
            desc: t("education_text"),
            img: Education,
        },
        {
            id: "2",
            title: t("transparency_and_honesty"),
            desc: t("transparency_text"),
            img: Transparency,
        },
        {
            id: "3",
            title: t("trades"),
            desc: t("trade_text"),
            img: Trades,
        },
        {
            id: "4",
            title: t("live_sessions"),
            desc: t("live_session_text"),
            img: LiveSession,
        },
        {
            id: "5",
            title: t("community"),
            desc: t("community_text"),
            img: Community,
        },
        {
            id: "6",
            title: t("purpose"),
            desc: t("purpose_text"),
            img: Purpose,
        },
    ];

    // Preview Courses Mutation
    const previewCoursesMutation = useMutation(previewCoursesApi, {
        onSuccess: data => {
            console.log("PreviewCourses", data);
            setLoading(false);
            if (data?.data) {
                setPreviewCourses(data?.data?.data);
                toast.success(data?.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    useEffect(() => {
        previewCoursesMutation.mutate();
    }, []);

    var body = document.body;

    const handleCourseClick = data => {
        setModalData(data);
        setIsModalOpen(true);
        body.classList.add("modal-open");
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        body.classList.remove("modal-open");
    };

    return (
        <>
            <Banner />
            {/* Benefits of working Component  */}

            <div className="container-fluid py-5 px-6">
                <div className="row px-2 benefit-working-heading">
                    <h2 className="mt-4">{t("benefits_of_working_with_us")}</h2>
                    {benefitObj.map(benefit => {
                        return (
                            <div key={benefit.id} className="col-12 col-md-6 col-lg-4 mt-4">
                                <BenefitWorking
                                    key={benefit.id}
                                    title={benefit.title}
                                    desc={benefit.desc}
                                    image={benefit.img}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Courses  */}

            <section className="popular-courses">
                <div className="container">
                    <div className="popular-courses-heading py-4 text-center">
                        <h2>{t("popular_courses")}</h2>
                    </div>
                    <div className="row px-2 pb-3">
                        {previewCourses.map(course => {
                            // console.log(course);
                            return (
                                <div key={course.id} className="col-12 col-md-6 col-lg-4 mt-3">
                                    <PreviewCourse
                                        key={course.id}
                                        id={course.id}
                                        title={course.course_title}
                                        instructor={course.instructor_name}
                                        // currentprice={course.currprice}
                                        // previousprice={course.prevprice}
                                        image={course.course_image}
                                        videoUrl={course.course_video}
                                        onClick={() => handleCourseClick(course)}
                                        data={course}
                                    />
                                </div>
                            );
                        })}

                        <PreviewCourseModal
                            data={modalData}
                            isOpen={isModalOpen}
                            onClose={handleModalClose}
                        />
                    </div>
                </div>
            </section>

            <Pricing />
            {/* <Counter /> */}
            <ManyCourses />
            {/* <Cta /> */}
        </>
    );
}
