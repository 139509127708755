import React from "react";
import { useLocation } from "react-router-dom";

function PaymentPage() {
    const location = useLocation();
    const title = location.state.title;
    const price = location.state.price;

    return (
        <section className="white-section py-5">
            <div className="container shadow py-5 px-6">
                <div className="row mb-4">
                    <div className="col-12 col-lg-12">
                        <h2>Payment Information</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 mb-5">
                        <p className="mb-1">Package Title:</p>
                        <h3 className="mb-4">{title}</h3>
                        <p className="mb-1">Package Price:</p>
                        <h3>${price}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <button className="lms-btn">Purchase</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PaymentPage;
