import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import YouTube from "react-youtube";
import { blogDetail } from "../api/blogApi";
import InnerBanner from "../components/banner/InnerBanner";

function InnerBlogPage() {
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState([]);

    let { id } = useParams();

    const blogDetailMutation = useMutation(blogDetail, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                setBlog(data?.data?.data);
                toast.success(data?.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    useEffect(() => {
        blogDetailMutation.mutate(id);
    }, []);

    return (
        <>
            <InnerBanner title="News" />
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="single-blog-title mb-4">
                            <h2>{blog[0]?.blog_title}</h2>
                        </div>
                        <div className="single-blog-img mb-5">
                            <img src={blog[0]?.blog_image} alt="" />
                        </div>
                        {blog[0]?.youtube_link && (
                            <div className="blog-youtube-video mb-4">
                                <YouTube videoId={blog[0]?.youtube_link} />
                            </div>
                        )}

                        <div
                            className="single-blog-desc"
                            dangerouslySetInnerHTML={{
                                __html: blog[0]?.description,
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default InnerBlogPage;
