// CONSTANT FILE

// let app_url   = 'http://localhost:3500';
// let app_url = 'http://api.localhost/genic_pay_backend/api/public/api';
// let app_url = "http://192.168.100.12/lms-back/api";
// export const app_url  = 'http://192.168.100.12:8000/api';
export const app_url = "https://portal-lms.genicpixels.com/api";

// let app_image = 'http://3.135.112.231:8080';

export default app_url;
