import React from "react";
import "./ManyCourses.css";

import { useTranslation } from "react-i18next";
import videoUrl from "../assets/video/home-vid.mp4";
import { useNavigate } from "react-router-dom";

export default function ManyCourses() {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <>
            <div className="container py-5">
                <div className="row justify-content-between">
                    <div className="col-12 col-md-5 col-lg-5 mb-4 many-courses-content d-flex flex-column align-items-start">
                        <h2>{t("one_website_with_all_course")}</h2>
                        <ul className="mt-3">
                            {t("all_what_you_need_as_trader")}
                            <li className="my-3">{t("all_videos_you_need_to_know")}</li>
                            <li className="mb-3">{t("all_tools_you_need_like")}</li>
                            <li>{t("live_sessions_where_we_meet")}</li>
                        </ul>

                        <button className="mt-4" onClick={() => navigate("/courses")}>
                            {t("explore_our_courses")}
                        </button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 cta-content text-center">
                        <h2>{t("from_zero_to_hero_learn_from_our_platform")}</h2>
                        <button className="mt-3 mb-4" onClick={() => navigate("/membership")}>
                            {t("get_started")}
                        </button>
                        <div className="course-video-section ratio ratio-16x9 mb-5">
                            <video width="100%" key={videoUrl} /* height="500px" */ controls>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                                <track
                                    src="fgsubtitles_en.vtt"
                                    kind="subtitles"
                                    srcLang="en"
                                    label="English"
                                />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
