import React, { useState } from "react";
import "./Course.css";
import Reviews from "../../assets/images/reviews-img.svg";
import FolderIcon from "../../assets/images/folder-icon.svg";
import Arrow from "../../assets/images/arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import PreviewCourseModal from "./PreviewCourseModal";
import Test from "../Test";
import { useTranslation } from "react-i18next";

export default function PreviewCourse(props) {
    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <>
            <div className="courses-card shadow mb-5">
                <div
                    // data-bs-toggle="modal"
                    // data-bs-target={`#staticBackdrop-${id}`}
                    onClick={props.onClick}
                >
                    <img src={props.image} className="img-fluid card-img" alt="" />
                    <div className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="course-profile">
                                <p className="mb-0">A</p>
                            </div>
                            <div className="course-profile-content">
                                <h4 className="mb-0 ps-3">{props.instructor}</h4>
                            </div>
                        </div>
                        <div className="d-flex review-text">
                            <img src={Reviews} className="img-fluid" alt="" />
                            <p className="mb-0 ps-2">(5.00)</p>
                        </div>
                    </div>
                    <div className="px-4 preview-title">
                        <h2 className="mt-3">{props.title}</h2>
                        <p>
                            {props.currentprice} <span>{props.previousprice}</span>
                        </p>
                    </div>
                </div>

                <div className="lesson-btns">
                    <div className="d-flex justify-content-center align-items-center">
                        {/* <div className="d-flex f-basis-50 py-3 lesson-border justify-content-center">
                            <img src={FolderIcon} className="img-fluid" alt="" />
                            <h3 className="ps-2 mb-0">Lesson 1</h3>
                        </div> */}
                        <div className="d-flex f-basis-100 justify-content-center py-3 justify-content-center">
                            <h3 className="mb-0">{t("view_detail")}</h3>
                            <img src={Arrow} className="img-fluid ps-2 pe-2" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
