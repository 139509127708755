import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import Video1 from "../../assets/video/video3.mp4";
import VideoRating from "../../assets/images/video-rating.png";
import Thumbnail from "../../assets/images/video-thumb.png";

function CourseVideo({ data }) {
    console.log("data", data);
    const [videoUrl, setVideoUrl] = useState("");

    const videoRef = useRef();

    const onVideoChange = vidUrl => {
        setVideoUrl(vidUrl);
    };

    useEffect(() => {
        videoRef.current?.load();
    }, [videoUrl]);

    useEffect(() => {
        setVideoUrl(
            data?.course_video && data?.course_video.length > 0
                ? data?.course_video[0].video_url
                : ""
        );
    }, [data]);

    // console.log("Data Recived", data);

    return (
        <div className="container-fluid px-6 py-5">
            <div className="row">
                <div className="col-lg-8">
                    <div className="main-video-wrapper">
                        <div className="course-video-section ratio ratio-16x9">
                            <video
                                width="100%"
                                key={videoUrl}
                                ref={videoRef}
                                /* height="500px" */ controls
                            >
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                                <track
                                    src="fgsubtitles_en.vtt"
                                    kind="subtitles"
                                    srcLang="en"
                                    label="English"
                                />
                                <track
                                    src="fgsubtitles_no.vtt"
                                    kind="subtitles"
                                    srcLang="no"
                                    label="Norwegian"
                                />
                            </video>
                        </div>
                        <div className="video-content">
                            <h2 className="main-video-title mt-3 mb-3">{data.course_title}</h2>
                            <div className="main-video-detail d-flex align-items-center">
                                <p className="instructor video-single-detail mb-0">
                                    <span>Instructor:</span> {data.instructor_name}
                                </p>
                                <p className="video-single-detail mb-0">{data.category}</p>
                                <div className="video-single-detail video-single-detail-last d-flex align-items-center">
                                    <img src={VideoRating} alt="" />
                                    <p className="mb-0 ms-2 video-rating-count">
                                        {data.average_rating}{" "}
                                        <span className="ms-1">(1 Rating)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <aside className="col-lg-4">
                    <div className="playlist-wrap">
                        {data?.course_video &&
                            data?.course_video.length > 0 &&
                            data?.course_video.map((item, index) => {
                                return (
                                    <div
                                        className="single-playlist-wrap mb-4 d-flex align-items-center"
                                        onClick={() => onVideoChange(item.video_url)}
                                        key={index}
                                    >
                                        <div className="thumbnail me-3">
                                            <img
                                                src={item.video_image}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="thumb-detail">
                                            <h6 className="mb-2">{item.title}</h6>
                                            <p className="mb-0">Duration: {item.video_duration}</p>
                                            {/* <p className="mb-0">
                                            <span>1.6m views.</span> <span>1 month ago</span>
                                        </p> */}
                                        </div>
                                    </div>
                                );
                            })}

                        {/* <div
                            className="single-playlist-wrap mb-4 d-flex align-items-center"
                            onClick={() => onVideoChange(require("../../assets/video/video2.mp4"))}
                        >
                            <div className="thumbnail me-3">
                                <img src={Thumbnail} className="img-fluid" alt="" />
                            </div>
                            <div className="thumb-detail">
                                <h6 className="mb-2">Faucibus eu ac</h6>
                                <p className="mb-0">Cursus senectus turpis</p>
                                <p className="mb-0">
                                    <span>1.6m views.</span> <span>1 month ago</span>
                                </p>
                            </div>
                        </div>
                        <div
                            className="single-playlist-wrap mb-4 d-flex align-items-center"
                            onClick={() => onVideoChange(require("../../assets/video/video3.mp4"))}
                        >
                            <div className="thumbnail me-3">
                                <img src={Thumbnail} className="img-fluid" alt="" />
                            </div>
                            <div className="thumb-detail">
                                <h6 className="mb-2">Faucibus eu ac</h6>
                                <p className="mb-0">Cursus senectus turpis</p>
                                <p className="mb-0">
                                    <span>1.6m views.</span> <span>1 month ago</span>
                                </p>
                            </div>
                        </div>
                        <div
                            className="single-playlist-wrap mb-4 d-flex align-items-center"
                            onClick={() => onVideoChange(require("../../assets/video/video1.mp4"))}
                        >
                            <div className="thumbnail me-3">
                                <img src={Thumbnail} className="img-fluid" alt="" />
                            </div>
                            <div className="thumb-detail">
                                <h6 className="mb-2">Faucibus eu ac</h6>
                                <p className="mb-0">Cursus senectus turpis</p>
                                <p className="mb-0">
                                    <span>1.6m views.</span> <span>1 month ago</span>
                                </p>
                            </div>
                        </div>
                        <div
                            className="single-playlist-wrap mb-4 d-flex align-items-center"
                            onClick={() => onVideoChange(require("../../assets/video/video2.mp4"))}
                        >
                            <div className="thumbnail me-3">
                                <img src={Thumbnail} className="img-fluid" alt="" />
                            </div>
                            <div className="thumb-detail">
                                <h6 className="mb-2">Faucibus eu ac</h6>
                                <p className="mb-0">Cursus senectus turpis</p>
                                <p className="mb-0">
                                    <span>1.6m views.</span> <span>1 month ago</span>
                                </p>
                            </div>
                        </div> */}
                    </div>
                </aside>
            </div>
        </div>
    );
}

export default CourseVideo;
