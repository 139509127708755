import Fetcher from "../library/Fetcher";

export const blogList = async data => {
    return Fetcher.get("/getBlogs", data);
};

export const blogDetail = async id => {
    return Fetcher.get(`/blogDetails/${id}`);
};

export default { blogList, blogDetail };
