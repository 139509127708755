import { createPortal } from "react-dom";
import React from "react";

export default function Spinner() {
    return createPortal(
        <div className="spinner-wrap">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>,
        document.body
    );
}
