import React, { useEffect, useRef } from "react";
import "./Banner.css";
import bannerImage from "../../assets/images/banner-image-person.png";
import videoUrl from "../../assets/video/home-vid.mp4";
import { useTranslation } from "react-i18next";

export default function Banner() {
    const { t } = useTranslation();

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.onloadeddata = () => {
                videoRef.current.play();
            };
        }
    }, []);

    return (
        <>
            <section className="bg-banner">
                <div className="container-fluid px-6 h-100">
                    <div className="row h-100">
                        <div className="col-12 col-md-6 col-lg-6 banner-content text-left d-flex flex-column justify-content-center align-items-start">
                            <h2>Forex Trader & Mentor</h2>
                            <p>Trader since 2014</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 banner-content-img d-flex justify-content-center align-items-center">
                            {/* <img src={bannerImage} className="img-fluid" alt="" /> */}
                            <div className="course-video-section ratio ratio-16x9 mb-5">
                                <video width="100%" ref={videoRef} controls>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                    <track
                                        src="fgsubtitles_en.vtt"
                                        kind="subtitles"
                                        srcLang="en"
                                        label="English"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
