import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
import filePdf from "../../assets/images/forex.pdf";
import i18next from "i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { logout } from "../../api/authApi";
import { toast } from "react-toastify";

export default function Navbar() {
    const [language, setLanguage] = useState();
    const [token, setToken] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let haveToken = sessionStorage.getItem("token");
        if (haveToken) {
            setToken(haveToken);
        }

        if (cookies.get("i18next") == "en") {
            setLanguage("AR");
        } else {
            setLanguage("EN");
        }
    }, []);

    const navigate = useNavigate();

    const onChangeLanguage = e => {
        e.preventDefault();
        if (cookies.get("i18next") == "en") {
            setLanguage("EN");
            i18next.changeLanguage("ar");
            document.body.dir = "rtl";
        } else if (cookies.get("i18next") == "ar") {
            setLanguage("AR");
            i18next.changeLanguage("en");
            document.body.dir = "ltr";
        }
    };

    const logoutMutation = useMutation(logout, {
        onSuccess: data => {
            console.log(data);
            setLoading(false);
            if (data?.data) {
                toast.success(data?.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            }

            sessionStorage.removeItem("token");
            sessionStorage.removeItem("id");
            navigate("/login");

            return;
        },
        onError: error => {
            console.log("error", error);
            setLoading(false);
            if (error?.response?.data?.errors) {
                setErrors(error?.response.data.errors);
                return;
            }
        },
    });

    const logoutHandler = () => {
        logoutMutation.mutate();
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid px-6">
                    <a className="navbar-brand" onClick={() => navigate("/")}>
                        <img src={Logo} className="img-fluid site-logo" alt="" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    aria-current="page"
                                    onClick={() => navigate("/")}
                                >
                                    {t("home")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="#"
                                    onClick={() => navigate("/about-us")}
                                >
                                    {t("about_us")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => navigate("/membership")}>
                                    {t("membership")}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => navigate("/news")}>
                                    {t("news")}
                                </a>
                            </li>
                            {token && (
                                <li className="nav-item">
                                    <a className="nav-link" onClick={() => navigate("/courses")}>
                                        {t("courses")}
                                    </a>
                                </li>
                            )}
                            {token && (
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        {t("tools")}
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => navigate("/risk-calculator")}
                                            >
                                                {t("risk_calculator")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => navigate("/trading-view")}
                                            >
                                                {t("trading_view")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                // onClick={() => navigate("/trading-view")}
                                                href={filePdf}
                                                // href="../../assets/images/counter-bg.png"
                                                download
                                            >
                                                {t("trade_tracker")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                // onClick={() => navigate("/trading-view")}
                                            >
                                                {t("brokers")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => navigate("/telegram-qr")}
                                            >
                                                {t("telegram")}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                href="https://www.forexfactory.com/"
                                                target="_blank"
                                            >
                                                {t("economic_calender")}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            )}
                        </ul>
                        <div className="nav-signup-btn d-flex">
                            {!token && (
                                <button
                                    className="nav-signin me-1 ms-1"
                                    onClick={() => navigate("/login")}
                                >
                                    {t("sign_in")}
                                </button>
                            )}
                            {!token && (
                                <button className="me-1 ms-1" onClick={() => navigate("/signup")}>
                                    {t("sign_up")}
                                </button>
                            )}

                            {token && (
                                <button className="me-1 ms-1" onClick={() => logoutHandler()}>
                                    Logout
                                </button>
                            )}
                            <div className="language-btn me-1 ms-1">
                                <button onClick={e => onChangeLanguage(e)}>{language}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
