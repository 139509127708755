import React from "react";

function CourseInfo() {
    return (
        <section className="course-info-section">
            <div className="container-fluid px-6">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button
                                    className="nav-link active flex-fill"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                >
                                    OVERVIEW
                                </button>
                                <button
                                    className="nav-link flex-fill"
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                >
                                    CURRICULUM
                                </button>
                                <button
                                    className="nav-link flex-fill"
                                    id="nav-contact-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-contact"
                                    aria-selected="false"
                                >
                                    INSTRUCTOR
                                </button>
                                <button
                                    className="nav-link flex-fill"
                                    id="nav-disabled-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-disabled"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-disabled"
                                    aria-selected="false"
                                >
                                    REVIEWS
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content" id="nav-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                                tabIndex="0"
                            >
                                <div className="summary-tab-content">
                                    <h3 className="mb-4">About The Course</h3>
                                    <p className="mb-4">
                                        Conveniently build adaptive users with front-end human
                                        capital. Appropriately unleash team building technology for
                                        goal-oriented paradigms. Dynamically generate interoperable
                                        e-business vis-a-visgoal-oriented value. Completely pursue
                                        fully tested content whereas multifunctional core
                                        competencies. Progressively scale team driven process
                                        improvements before premier functionalities. Holisticly
                                        cultivate intermandated methodologies rather than virtual
                                        technology. Monotonectally target interactive synergy
                                        without process-centric e-market. Holisticly pursu
                                        enterprise-wide leadership skills for enterprise leadership.
                                        Collaboratively underwhelm standardized expertise after
                                        effective bandwidth.
                                    </p>
                                    <p className="mb-5">
                                        Enthusiastically aggregate ethical systems for standardized
                                        mindshare. Energistically target resource maximizing
                                        leadership skills without backward-compatible action items.
                                        Credibly impact alternative expertise vis-a-vis economically
                                        sound results. Dynamically synergize empowered benefits
                                        through functional partnerships. Authoritatively empower
                                        prospective infomediaries for interactive content.
                                        Synergistically embrace 2.0 paradigms through professional
                                        intellectual capital. Interactively strategize parallel
                                        growth strategies without out-of-the-box web services.
                                    </p>

                                    <h3 className="mb-4">What Will You Learn?</h3>
                                    <p>
                                        I take my courses very seriously but at the same time I try
                                        to make it fun since I know how difficult learning from an
                                        instructor with a monotone voice or boring attitude is. This
                                        course is fun, and when you need some energy to keep going,
                                        you will get it from me.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-profile"
                                role="tabpanel"
                                aria-labelledby="nav-profile-tab"
                                tabIndex="0"
                            >
                                <div className="summary-tab-content">
                                    <h3 className="mb-4">About The Course</h3>
                                    <p className="mb-4">
                                        Conveniently build adaptive users with front-end human
                                        capital. Appropriately unleash team building technology for
                                        goal-oriented paradigms. Dynamically generate interoperable
                                        e-business vis-a-visgoal-oriented value. Completely pursue
                                        fully tested content whereas multifunctional core
                                        competencies. Progressively scale team driven process
                                        improvements before premier functionalities. Holisticly
                                        cultivate intermandated methodologies rather than virtual
                                        technology. Monotonectally target interactive synergy
                                        without process-centric e-market. Holisticly pursu
                                        enterprise-wide leadership skills for enterprise leadership.
                                        Collaboratively underwhelm standardized expertise after
                                        effective bandwidth.
                                    </p>
                                    <p className="mb-5">
                                        Enthusiastically aggregate ethical systems for standardized
                                        mindshare. Energistically target resource maximizing
                                        leadership skills without backward-compatible action items.
                                        Credibly impact alternative expertise vis-a-vis economically
                                        sound results. Dynamically synergize empowered benefits
                                        through functional partnerships. Authoritatively empower
                                        prospective infomediaries for interactive content.
                                        Synergistically embrace 2.0 paradigms through professional
                                        intellectual capital. Interactively strategize parallel
                                        growth strategies without out-of-the-box web services.
                                    </p>

                                    <h3 className="mb-4">What Will You Learn?</h3>
                                    <p>
                                        I take my courses very seriously but at the same time I try
                                        to make it fun since I know how difficult learning from an
                                        instructor with a monotone voice or boring attitude is. This
                                        course is fun, and when you need some energy to keep going,
                                        you will get it from me.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-contact"
                                role="tabpanel"
                                aria-labelledby="nav-contact-tab"
                                tabIndex="0"
                            >
                                <div className="summary-tab-content">
                                    <h3 className="mb-4">About The Course</h3>
                                    <p className="mb-4">
                                        Conveniently build adaptive users with front-end human
                                        capital. Appropriately unleash team building technology for
                                        goal-oriented paradigms. Dynamically generate interoperable
                                        e-business vis-a-visgoal-oriented value. Completely pursue
                                        fully tested content whereas multifunctional core
                                        competencies. Progressively scale team driven process
                                        improvements before premier functionalities. Holisticly
                                        cultivate intermandated methodologies rather than virtual
                                        technology. Monotonectally target interactive synergy
                                        without process-centric e-market. Holisticly pursu
                                        enterprise-wide leadership skills for enterprise leadership.
                                        Collaboratively underwhelm standardized expertise after
                                        effective bandwidth.
                                    </p>
                                    <p className="mb-5">
                                        Enthusiastically aggregate ethical systems for standardized
                                        mindshare. Energistically target resource maximizing
                                        leadership skills without backward-compatible action items.
                                        Credibly impact alternative expertise vis-a-vis economically
                                        sound results. Dynamically synergize empowered benefits
                                        through functional partnerships. Authoritatively empower
                                        prospective infomediaries for interactive content.
                                        Synergistically embrace 2.0 paradigms through professional
                                        intellectual capital. Interactively strategize parallel
                                        growth strategies without out-of-the-box web services.
                                    </p>

                                    <h3 className="mb-4">What Will You Learn?</h3>
                                    <p>
                                        I take my courses very seriously but at the same time I try
                                        to make it fun since I know how difficult learning from an
                                        instructor with a monotone voice or boring attitude is. This
                                        course is fun, and when you need some energy to keep going,
                                        you will get it from me.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="nav-disabled"
                                role="tabpanel"
                                aria-labelledby="nav-disabled-tab"
                                tabIndex="0"
                            >
                                <div className="summary-tab-content">
                                    <h3 className="mb-4">About The Course</h3>
                                    <p className="mb-4">
                                        Conveniently build adaptive users with front-end human
                                        capital. Appropriately unleash team building technology for
                                        goal-oriented paradigms. Dynamically generate interoperable
                                        e-business vis-a-visgoal-oriented value. Completely pursue
                                        fully tested content whereas multifunctional core
                                        competencies. Progressively scale team driven process
                                        improvements before premier functionalities. Holisticly
                                        cultivate intermandated methodologies rather than virtual
                                        technology. Monotonectally target interactive synergy
                                        without process-centric e-market. Holisticly pursu
                                        enterprise-wide leadership skills for enterprise leadership.
                                        Collaboratively underwhelm standardized expertise after
                                        effective bandwidth.
                                    </p>
                                    <p className="mb-5">
                                        Enthusiastically aggregate ethical systems for standardized
                                        mindshare. Energistically target resource maximizing
                                        leadership skills without backward-compatible action items.
                                        Credibly impact alternative expertise vis-a-vis economically
                                        sound results. Dynamically synergize empowered benefits
                                        through functional partnerships. Authoritatively empower
                                        prospective infomediaries for interactive content.
                                        Synergistically embrace 2.0 paradigms through professional
                                        intellectual capital. Interactively strategize parallel
                                        growth strategies without out-of-the-box web services.
                                    </p>

                                    <h3 className="mb-4">What Will You Learn?</h3>
                                    <p>
                                        I take my courses very seriously but at the same time I try
                                        to make it fun since I know how difficult learning from an
                                        instructor with a monotone voice or boring attitude is. This
                                        course is fun, and when you need some energy to keep going,
                                        you will get it from me.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CourseInfo;
