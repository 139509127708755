import React from "react";
import "./Cta.css";
import { useTranslation } from "react-i18next";

export default function Cta() {
    const { t } = useTranslation();

    return (
        <section>
            <div className="container-fluid">
                <div className="row py-4">
                    <div className="col-12 cta-content text-center py-5">
                        <h1>
                            {t("learn_from_our_platform")} <br></br>
                            {t("that_take_you_next_level")}
                        </h1>
                        <button className="mt-3">{t("get_started")}</button>
                    </div>
                </div>
            </div>
        </section>
    );
}
