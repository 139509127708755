import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import app_url from "../config/constants";

// export default axios.create({
//   baseURL: app_url,
//   timeout: 15000,
//   headers: {
//     "Accept": "application/json",
//     "Content-type": "application/json"
//   },
// });

class Fetcher {
    constructor() {
        this.axiosSetup = null;
        // this.bearer;
        this.setup();
    }

    setup = async () => {
        this.axiosSetup = axios.create({
            baseURL: app_url,
            timeout: 15000,
            headers: {
                // "Access-Control-Allow-Headers": "*",
                Accept: "application/json",
                "Content-type": "application/json",
            },
        });

        this.axiosSetup.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                // toast.error('Oops! Network Error.', {
                //   position: "top-right",
                //   autoClose: 5000,
                //   hideProgressBar: true,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: false,
                //   progress: undefined,
                // });
                if (!error.response?.status) {
                    toast.error("Oops! Network Error.", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else if (error.response.status == 404) {
                    toast.error("Oops! 404 Not Found! ", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
                return Promise.reject(error);
            }
        );

        let token = null;

        token = sessionStorage.getItem("token");

        if (token) {
            this.axiosSetup.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
    };

    get = async (route, params, extra) => {
        return this.axiosSetup.get(route, params);
    };

    post = async (route, params, extra) => {
        return this.axiosSetup.post(route, params, extra);
    };

    patch = async (route, params, extra) => {
        return this.axiosSetup.patch(route, params);
    };

    put = async (route, params, extra) => {
        return this.axiosSetup.put(route, params);
    };

    removeToken = () => {
        delete this.axiosSetup.defaults.headers.common.Authorization;
    };
}

export default new Fetcher();
