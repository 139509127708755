import React from "react";
import "./BenefitWorking.css";
import banefitLogo1 from "../../assets/images/benefit-working-icon-1.svg";

export default function BenefitWorking({ title, desc, image }) {
    return (
        <>
            {/* <div className='row'>
                <div className='col-12 benefit-working-heading'>
                </div>
            </div> */}

            <div className="d-flex justify-content-center align-items-start benefit-working mt-4">
                <div className="benefit-working-logo">
                    <img src={image} alt="" />
                </div>
                <div className="d-flex flex-column benefit-working-content ps-3 pe-3">
                    <h3>{title}</h3>
                    <p>{desc}</p>
                </div>
            </div>
        </>
    );
}
