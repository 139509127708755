import Fetcher from "../library/Fetcher";

export const previewCoursesApi = async data => {
    return Fetcher.get("/homePageCourses", data);
};

export const courseListApi = async data => {
    return Fetcher.get("/courses", data);
};

export const courseDetailApi = async id => {
    const response = Fetcher.get(`/courseDetail/${id}`);
    return response;
};

export default { previewCoursesApi, courseListApi, courseDetailApi };
